export interface ITest {
  id: number;
  name: string;
  image: string;
  total_invitation?: number;
  total_ongoing?: number;
  total_done?: number;
}

export enum TestStatusEnum {
  Unopened = "Unopened",
  Opened = "Opened",
  Done = "Done",
}
