import { TestStatusEnum } from "@/interfaces/test";

interface IReturnBadgeVariant {
  (status: string): {
    variant: string;
    text: string;
  };
}

export default function useTestStatusBadge(): {
  getBadgeVariant: IReturnBadgeVariant;
} {
  // get badge class by status
  const getBadgeVariant = (
    status: string
  ): {
    variant: string;
    text: string;
  } => {
    const badgeStatus: { [key: string]: string } = {
      [TestStatusEnum.Unopened]: "gray",
      [TestStatusEnum.Opened]: "yellow",
      [TestStatusEnum.Done]: "green",
    };

    let badgeText = "Belum Mulai";

    switch (status) {
      case TestStatusEnum.Opened:
        badgeText = "Sedang Berjalan";
        break;
      case TestStatusEnum.Done:
        badgeText = "Selesai";
        break;
      default:
        badgeText = "Belum Mulai";
        break;
    }

    return {
      variant: badgeStatus[status] || "gray",
      text: badgeText,
    };
  };

  return {
    getBadgeVariant,
  };
}
