import httpClient from "./http-client";
import authHeader from "./auth-header";
import { AxiosRequestHeaders } from "axios";

const getTests = async (size = 10): Promise<any> => {
  const params = {
    size,
  };

  const { data } = await httpClient.get("/test", {
    params,
    headers: authHeader() as AxiosRequestHeaders,
  });

  return data;
};

interface ITest {
  code: string;
  description: string;
  id: number;
  image: string;
  name: string;
  status: string;
  user_bundle_result_id: string | null;
}

interface ITestsParticipantResponse {
  data: ITest[];
}

const getTestsParticipant = async (
  size = 10
): Promise<ITestsParticipantResponse> => {
  const params = {
    size,
  };

  const { data } = await httpClient.get("/b-participant/v1/bundle", {
    params,
    headers: authHeader() as AxiosRequestHeaders,
  });

  return data;
};

interface ITestDetailParticipantResponse {
  data: {
    description: string;
    image: string;
    instructions: string;
    name: string;
    total_example: number;
    total_question: number;
    type: string;
  };
}

const getTestDetailParticipant = async (
  id: number
): Promise<ITestDetailParticipantResponse> => {
  const { data } = await httpClient.get(
    `/b-participant/v1/test/detail?bundleId=${id}`,
    {
      headers: authHeader() as AxiosRequestHeaders,
    }
  );

  return data;
};

interface IAnswers {
  id: number;
  question_id: number | null;
  score: number;
  answer_type_id: number;
  answer: string;
}

interface IAnswerType {
  id: number;
  additional_attribute: {
    is_example: boolean;
  };
  answers: IAnswers;
}

interface IQuestion {
  id: number;
  answer_type_id: number;
  answer_type: IAnswerType;
  code: string;
  question: string;
}

interface ISections {
  [key: string]: {
    status: string;
    questions: IQuestion;
  };
}

interface ITestListQuestionParticipantResponse {
  data: {
    example: IQuestion[];
    test: IQuestion[];
    sections?: ISections;
  };
}

const getTestListQuestionParticipant = async (
  id: number
): Promise<ITestListQuestionParticipantResponse> => {
  const { data } = await httpClient.get(
    `/b-participant/v1/test/questions?bundleId=${id}`,
    {
      headers: authHeader() as AxiosRequestHeaders,
    }
  );

  return data;
};

const getTestResult = async (params: {
  bundleId: number;
  resultId: string | string[];
}): Promise<any> => {
  const { bundleId, resultId } = params;

  const { data } = await httpClient.get(
    `/b-participant/v1/bundle/${bundleId}/result/${resultId}`,
    {
      headers: authHeader() as AxiosRequestHeaders,
    }
  );

  return data;
};

const downloadTestResult = async (params: {
  bundleId: number;
  resultId: string | string[];
}): Promise<any> => {
  const { bundleId, resultId } = params;

  const { data } = await httpClient.get(
    `/b-participant/v1/bundle/${bundleId}/result/${resultId}/download`,
    {
      headers: authHeader() as AxiosRequestHeaders,
    }
  );

  return data;
};

interface IQuestionParticipantPayload {
  bundle_id: number;
  user_answer: {
    [key: number]: number;
  };
}

interface IQuestionParticipantResponse {
  data: {
    status: string;
  };
  message: string;
}

const submitQuestionParticipant = async (
  req: IQuestionParticipantPayload
): Promise<IQuestionParticipantResponse> => {
  const { data } = await httpClient.post(`/b-participant/v1/test/submit`, req, {
    headers: authHeader() as AxiosRequestHeaders,
  });

  return data;
};

export default {
  getTests,
  getTestsParticipant,
  getTestDetailParticipant,
  getTestListQuestionParticipant,
  getTestResult,
  downloadTestResult,
  submitQuestionParticipant,
};
