
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    variant: String,
    label: String,
    isRoundedFull: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const getBadgeClass = computed(() => {
      return `status-badge--${props.variant} ${
        props.isRoundedFull ? "rounded-full" : "rounded"
      }`;
    });

    return {
      getBadgeClass,
    };
  },
});
