
import { AxiosError } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import moment from "moment";
import { useToast } from "vue-toastification";

// services
import profileService from "@/services/profile.service";
import testService from "@/services/test.service";
import projectService from "@/services/project.service";

// composable
import useTestStatusBadge from "@/composables/testStatusBadge";

// components
import Badge from "@/components/atoms/Badge.atoms.vue";

import GcHeader from "@/components/molecules/GcHeader/index.vue";

import { IProfile, ITest, IDetailProject } from "./interface";

export default defineComponent({
  components: { Badge, GcHeader },
  setup() {
    const router = useRouter();
    const isLoading = ref<boolean>(true);
    const toast = useToast();

    // get profile
    const initProfile: IProfile = {
      name: "",
      place_of_birth: 0,
      institution_id: 0,
      last_education_from_year: "",
      last_education_to_year: "",
      profession: "",
      industry_id: 0,
      work_experience_year: 0,
      marital_status: "",
      phone: "",
      date_of_birth: "",
      province_id: 0,
      gender: "",
      last_education: "",
    };
    const profileData = ref<IProfile>(initProfile);
    const listTest = ref<ITest[]>([]);

    const initDetailProject: IDetailProject = {
      ended_at: "",
      name: "",
      started_at: "",
    };
    const detailProject = ref<IDetailProject>(initDetailProject);

    const getProfile = async () => {
      if (!localStorage.getItem("profileData")) {
        const { data } = await profileService.getProfileBParticipant();
        if (data) {
          profileData.value = {
            ...profileData.value,
            ...data,
          };
          localStorage.setItem(
            "profileData",
            JSON.stringify(profileData.value)
          );
        }
      } else {
        profileData.value = JSON.parse(
          localStorage.getItem("profileData") || ""
        );
      }
    };

    const getList = async () => {
      try {
        isLoading.value = true;
        const { data } = await testService.getTestsParticipant();

        if (data.length > 0) {
          listTest.value = data.map((item: ITest) => ({
            ...item,
            user_bundle_result_created_at: item.user_bundle_result_created_at
              ? moment(item.user_bundle_result_created_at).format(
                  "DD MMMM YYYY hh:mm"
                )
              : "-",
          }));
        }
      } catch (e) {
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
      } finally {
        isLoading.value = false;
      }
    };

    const getDetailProject = async () => {
      try {
        isLoading.value = true;
        const { data } = await projectService.getProjectParticipant();

        if (data) {
          detailProject.value = {
            ...detailProject.value,
            ...data,
            ended_at: moment(data.ended_at).format("DD MMMM YYYY hh:mm"),
            started_at: moment(data.started_at).format("DD MMMM YYYY hh:mm"),
          };
        }
      } catch (e) {
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await getProfile();
      await getList();
      await getDetailProject();
    });

    const goUpdateProfile = () => {
      router.push("/update-profile");
    };

    const { getBadgeVariant } = useTestStatusBadge();

    const startAssesment = (id: number) => {
      router.push(`/test/detail/${id}`);
    };

    const seeReport = (params: { bundleId: number; resultId: string }) => {
      const { bundleId, resultId } = params;
      router.push(`/test/report/${bundleId}/${resultId}`);
    };

    return {
      isLoading,
      profileData,
      goUpdateProfile,
      listTest,
      getBadgeVariant,
      startAssesment,
      seeReport,
      detailProject,
    };
  },
});
